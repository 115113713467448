import React, { Component } from 'react';
import logo from './logo.png';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = {
    logo: {
        height: '30vmin'
    },
    root: {
        minHeight: '100vh'
    }
}

class MainPage extends Component {

    render() {
        const { classes } = this.props;
        return (
            <Grid className={classes.root} container direction='column' alignItems='center' justify='center'>
                <Grid item >
                    <img className={classes.logo} src={logo} alt="Logo" />
                </Grid>
                <Grid item>
                    <Typography variant='display1' gutterBottom>Rosario</Typography>
                </Grid>
            </Grid >

        );
    }
}

export default withStyles(styles)(MainPage);
