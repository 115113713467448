import React, { Component } from 'react'
import 'typeface-roboto';
import CssBaseline from '@material-ui/core/CssBaseline';
import DocumentTitle from 'react-document-title';
import MainPage from './MainPage';


export default class App extends Component {
  render() {
    return <CssBaseline>
      <DocumentTitle>
        <MainPage />
      </DocumentTitle>
    </CssBaseline>
  }
}